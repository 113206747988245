<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getUserList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <!-- <select class="font-16 w-120px h-40px pl-10">
                                <option value="">센터선택</option>
                                <option>강남센터</option>
                            </select> -->

                            <input type="text" v-model="name" class="w-200px h-40px pd-00 pl-20" placeholder="상담사명">
                            <a class="btn_search ml-10 pointer" @click="getUserList()">Search<span></span></a>
                        </div>
                        <div class="btns mb-30">
                            <a class="btn_new2 pointer" @click="register()">상담사등록</a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담사명</th>
                                <th>성별</th>
                                <th>전문분야</th>
                                <th>승인유무</th>
                            </tr>
                            <tr>
                                <td colspan="4">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>상담사명</th>
                                <th>성별</th>
                                <th>전문분야</th>
                                <th>승인유무</th>
                            </tr>
                            <tr v-for="(item, index) of userList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td class="underline pointer" @click="detailCard(item.idx)">{{item.name}}</td>
                                <td>{{item.gender || '-'}}</td>
                                <td>{{item.proName || '-'}}</td>
                                <td v-if="item.permission === 'Y'">승인</td>
                                <td v-if="item.permission !== 'Y'">미승인</td>
                            </tr>
                            <tr v-if="userList.length === 0">
                                <td colspan="4">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        name: '', // 상담사 검색 이름

        userList: [],
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('pageNum') !== 'undefined' && sessionStorage.getItem('pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('pageNum'))
        }
        if(sessionStorage.getItem('idxCrmCenter') !== 'undefined' && sessionStorage.getItem('idxCrmCenter') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('idxCrmCenter')
        }
        if(sessionStorage.getItem('name') !== 'undefined' && sessionStorage.getItem('name') !== null) {
            this.name = sessionStorage.getItem('name')
        }

        this.getUserList()
    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getUserList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getUserList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getUserList()
            }
        },

        // 검색 api
        getUserList() {
            var params = {
                idxCrmCenter: this.idxCrmCenter,
                name: this.name,
                permission: '',
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }

            sessionStorage.setItem('pageNum', this.pageNum);
            sessionStorage.setItem('idxCrmCenter', this.idxCrmCenter);
            sessionStorage.setItem('name', this.name);

            this.loading = true;

            this.axios.get('/api/v12user/list', {
                    params: params
                })
                .then(res => {
                    this.userList = res.data.userList
                    if (res.data.userList) {
                        this.listLength = res.data.userListCount
                    }

                    this.idxCrmCenter = res.data.idxCrmCenter
                    this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                    this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                    this.pagePart = Math.ceil(this.pageNum / 10)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 상담등록 페이지로 이동
        register() {
            this.$router.push(`/convention/counselor_manage_register?idxCrmCenter=${this.idxCrmCenter}`)
        },

        // 상담수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/convention/counselor_manage_modify?idx=${idx}`)
        },

        // 상담사 카드 페이지로 이동
        detailCard(idx) {
            this.$router.push(`/convention/counselor_manage_card?idx=${idx}`)
        },

        del(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/user/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response.data.path == '/login/msg') {
                            alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                            this.$router.push('/login')
                        } else {
                            alert('관리자에게 문의하세요')
                        }
                    })
            } else {
                return false
            }
        }
    }
}
</script>
